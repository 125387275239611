import AppLogo from '@/components/assets/AppLogo';
import AppAvatarMenu from '@/components/avatars/AppAvatarMenu';
import RoundIconAvatar from '@/components/avatars/RoundIconAvatar';
import EmptyStateCard from '@/components/cards/EmptyStateCard';
import ScrollableChildContainer, {
  ScrollableChild,
} from '@/components/containers/ScrollableChildContainer';
import { FilePlus } from '@/components/icons';
import { LoadingPageProgress } from '@/components/layouts/LoadingPage';
import BasicToolbar from '@/components/toolbars/BasicToolbar';
import { useNavigationContext } from '@/context/navigationContext';
import { useOrgProjectLocalStorage } from '@/hooks/useOrgProjectLocalStorage';
import { getHref, navRoutes, QueryParams } from '@/utils/routes';
import withRequiredAuth from '@/utils/withRequiredAuth';
import { useAllOrganizations } from '@formbio/api';
import { Box, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const APP_ROOT_TITLE = 'Projects';

const IndexPage = () => {
  const router = useRouter();
  const { setPageTitle } = useNavigationContext();
  const { allOrganizations } = useAllOrganizations();
  // keep loading screen until redirects are done
  const [isRedirecting, setIsRedirecting] = useState(true);
  const { storedOrgId, storedProjectId } = useOrgProjectLocalStorage();

  /**
   * Tries to redirect the user to project home
   * or organization page if localStorage has the params
   * the respective routes need.
   */
  if (storedOrgId && storedProjectId) {
    // take user to project home
    router.push(
      getHref(router, navRoutes.projectHome, {
        [QueryParams.orgId]: storedOrgId,
        [QueryParams.projId]: storedProjectId,
      }),
    );
  } else if (storedOrgId) {
    // take user to organization home
    router.push(
      getHref(router, navRoutes.organization, {
        [QueryParams.orgId]: storedOrgId,
      }),
    );
  }

  useEffect(() => {
    setPageTitle(APP_ROOT_TITLE);
  }, []);

  useEffect(() => {
    setIsRedirecting(true);
    if (!storedOrgId && allOrganizations?.length) {
      // take user to first organization home found
      router.push(
        getHref(router, navRoutes.organization, {
          [QueryParams.orgId]: allOrganizations[0].id,
        }),
      );
    }
    if (allOrganizations && !allOrganizations.length) {
      setIsRedirecting(false);
    }
  }, [allOrganizations]);

  return (
    <Box display='flex'>
      <Box height='100vh' display='flex' flexDirection='column' flexGrow={1}>
        <BasicToolbar position='fixed' backgroundColor='primary.main'>
          <Stack
            width='100%'
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <AppLogo minied sx={{ my: 0 }} />
            <Typography variant='h4' color='primary.contrastText'>
              {APP_ROOT_TITLE}
            </Typography>
            <AppAvatarMenu />
          </Stack>
        </BasicToolbar>
        <Box // this box is responsible for setting the responsive height leftover from after the toolbar
          component='main'
          flexGrow={1}
          overflow='hidden'
        >
          <ScrollableChildContainer
            sx={{
              px: 14,
              backgroundImage: `url('/images/bubbles.jpg')`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionY: '100%',
              backgroundSize: '100%',
            }}
          >
            {isRedirecting ? (
              <LoadingPageProgress />
            ) : (
              <ScrollableChild
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 15,
                }}
              >
                <EmptyStateCard
                  topRow={
                    <RoundIconAvatar>
                      <FilePlus />
                    </RoundIconAvatar>
                  }
                  bottomRow={
                    <Typography variant='h4' textAlign='center'>
                      You currently do not belong to any organization or project
                    </Typography>
                  }
                />
              </ScrollableChild>
            )}
          </ScrollableChildContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default withRequiredAuth(IndexPage);
