import { Box, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export default function RoundIconAvatar({
  color = 'primary',
  backgroundColor = 'neutral.100',
  children,
}: {
  color?: string;
  backgroundColor?: string;
  children: ReactNode;
}) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor,
        borderRadius: '50%',
        color,
        width: theme.spacing(8),
        height: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </Box>
  );
}
