import { Box, Typography } from '@mui/material';
import Loading from '../animations/Loading';
import AppPage from './templates/AppPage';

export default function LoadingPage() {
  return (
    <AppPage>
      <LoadingPageProgress />
    </AppPage>
  );
}

export function LoadingPageProgress() {
  return (
    <Box textAlign='center' paddingX='2em'>
      <Loading sx={{ marginTop: '10vh', marginBottom: '1em' }} />
      <Typography>Loading...</Typography>
    </Box>
  );
}
