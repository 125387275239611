import { Card, Stack, SxProps } from '@mui/material';
import { ReactNode } from 'react';

/**
 * A simple card component with 2 centered row.
 * Used as an empty state for projects and orgs
 * with an icon on topRow and a text on bottomRow
 */
export default function EmptyStateCard({
  topRow,
  bottomRow,
  sx = {},
}: {
  topRow: ReactNode;
  bottomRow: ReactNode;
  sx?: SxProps;
}) {
  return (
    <Card
      variant='outlined'
      sx={{
        height: '100%',
        py: 3,
        px: 10,
        width: '600px',
        ...sx,
      }}
    >
      <Stack spacing={2} alignItems='center'>
        {topRow}
        {bottomRow}
      </Stack>
    </Card>
  );
}
